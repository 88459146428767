import {cdek_plugin_city_list_request_path, cdek_plugin_departure_city_list_request_path, edit_profile_path} from '../routes';
import select2 from 'select2';
import Rails from "@rails/ujs"
import {gotoUrl, submitByUjs} from "./common_methods";

$.fn.select2.amd.define('select2/i18n/ru',[],require("select2/src/js/select2/i18n/ru"));
$.fn.select2.amd.define('select2/i18n/zh-CN',[],require("select2/src/js/select2/i18n/zh-CN"));
export class CdekCalculator {

  constructor(calc) {
    this.self = calc;
    this.contentTypeInput = $('#cdek_plugin_calculator_form_content_type');
    this.cities = $('[data-cdekcity]');
    this.calcRequestForm = $('#new_cdek_plugin_calculator_form');
    this.message = $('#calc-message');
    this.contextParams = this.message.find('[name="task[context_params]"]');

    this.errors = $('#calc-errors');
    this.cityName = this.self.data('city');
    // this.profileReady = calc.data('profileReady');
    this.unauthorized = $('#login-modal').length > 0
    // this.contextParams.parent('form').off('ajax:beforeSend').on('ajax:beforeSend', e => {
    //   if ($('#login-modal').length === 0 && !this.profileReady){
    //     gotoUrl(edit_profile_path());
    //     return false;
    //   } else {
    //     return true;
    //   }
    // });
    this.inputPrefix = 'cdek_plugin_calculator_form';
    this.params = {};
    this.contentTabs = $('#calculator-tabs');
    this.options = {
      theme: 'bootstrap',
      language: $('meta[name=language]').attr('content'),
      minimumInputLength: 1,
      width: 'resolve',
      ajax: {
        dataType: 'json',
        delay: 250
      }
    };
    this.init();
  }

  init() {
    $('#calculator-tabs .nav-link').first().addClass('active');
    $('#cdek-calc').removeClass('d-none');
    this.cities.each((function(_this) {
      return function(i, select) {
        return _this.applySelect2(select);
      };
    })(this));
    this.calcRequestForm.on('ajax:success', (function(_this) {
      return function(e) {
        if (_this.unauthorized) {
          sessionStorage['calculatorResponse'] = JSON.stringify(e.detail);
        }
        return _this.processResponse(e.detail);
      };
    })(this));
    this.calcRequestForm.on('ajax:error', (function(_this) {
      return function(e) {
        return _this.processErrors(e.detail);
      };
    })(this));
    this.contentTabs.find('.nav-link').on('click', (function(_this) {
      return function(e) {
        return _this.updateContent(e.target.id);
      };
    })(this));
    if (this.cityName) {
      this.selectCity(this.cityName);
    }
    if (this.restorable()) {
      this.restoreParameters();
    }
    this.updateContent(this.contentType());
  };

  updateContent(type) {
    var $group, toggle;
    $group = $('.form-group.row.dimensions');
    toggle = type === 'goods';
    $group.toggle(toggle);
    $group.find('input').prop('disabled', !toggle);
    this.contentTypeInput.val(type);
    return this.params['content_type'] = type;
  }

  contentType() {
    return this.contentTabs.find('.nav-link.active').attr('id');
  }

  restorable() {
    return !this.unauthorized && !!sessionStorage['calculatorResponse'] && !!sessionStorage['calculatorParams'];
  }

  applySelect2(select) {
    var locale;
    locale = select.getAttribute('data-locale');
    switch (select.getAttribute('data-cdekcity')) {
      case 'russia':
        this.options.ajax.url = cdek_plugin_city_list_request_path({
          country_code: 'RU',
          locale: locale,
          name_with_region: true
        });
        break;
      case 'usa':
        this.options.ajax.url = cdek_plugin_city_list_request_path({
          country_code: 'US',
          locale: locale,
          name_with_region: false
        });
        break;
      case 'china':
        this.options.ajax.url = cdek_plugin_departure_city_list_request_path({
          locale: locale
        });
        break;
      default:
        false;
    }
    $(select).select2(this.options).on('select2:select', (function(_this) {
      return function(e) {
        return _this.updateFromEvent(e);
      };
    })(this));

    $(select).on('select2:open', () => {
      // HACK
      document.querySelector('.select2-search__field').focus();
    })
  }

  restoreParameters() {
    var tariff;
    this.contextParams.val(sessionStorage['calculatorParams']);
    sessionStorage.removeItem('calculatorParams');
    tariff = JSON.parse(sessionStorage['calculatorResponse']);
    this.showResponseMessage(tariff[0]);
    sessionStorage.removeItem('calculatorResponse');
    submitByUjs(this.contextParams.parent('form'));
  }

  processErrors(_arg) {
    var $ul, messages, response, status, xhr;
    response = _arg[0], status = _arg[1], xhr = _arg[2];
    this.message.hide();
    this.errors.hide();
    $ul = this.errors.find('ul').empty();
    messages = [];
    if (status === 'Bad Gateway') {
      response.forEach(function(_arg1) {
        var message;
        message = _arg1.message;
        return messages.push(message);
      });
    }
    if (status === 'Unprocessable Entity') {
      response.full_messages.forEach(function(message) {
        return messages.push(message);
      });
    }
    messages.forEach(function(message) {
      return $('<li/>').text(message).appendTo($ul);
    });
    this.errors.show();
  }


  filteredTariffs(_arg) {
    var codes, tariff_codes;
    tariff_codes = _arg.tariff_codes;
    codes = this.self.data('tariffCodes') || [];
    return tariff_codes.filter((function(_this) {
      return function(tariff) {
        return codes.includes(tariff.tariff_code) && ("" + tariff.delivery_mode) === $("[name='" + _this.inputPrefix + "[delivery_mode]']").val();
      };
    })(this));
  }

  processResponse(_arg) {
    var $ul, emptyMessage, response, status, tariff, xhr;
    response = _arg[0], status = _arg[1], xhr = _arg[2];
    this.message.hide();
    this.errors.hide();
    tariff = response;
    if (tariff) {
      this.updateFromResponse(tariff);
      this.updateFromRequest();
      this.contextParams.val(JSON.stringify(this.params));
      if (this.unauthorized) {
        sessionStorage['calculatorParams'] = this.contextParams.val();
      }
      this.showResponseMessage(tariff);
    } else {
      emptyMessage = this.errors.data('emptytariffmessage');
      $ul = this.errors.find('ul').empty();
      $('<li/>').text(emptyMessage).appendTo($ul);
      this.errors.show();
    }
  }

  showResponseMessage = function(_arg) {
    var $calcMsgContainer, delivery_sum, period_max, period_min, tariff_name;
    tariff_name = _arg.tariff_name, delivery_sum = _arg.delivery_sum, period_min = _arg.period_min, period_max = _arg.period_max;
    $calcMsgContainer = this.message;
    $calcMsgContainer.find('span[data-attribute="city"]').html(this.params['receiverCity']);
    $calcMsgContainer.find('span[data-attribute="weight"]').html(this.params['weight'] + ' кг');
    $calcMsgContainer.find('span[data-attribute="delivery_sum"]').html(delivery_sum + ' руб');
    $calcMsgContainer.find('span[data-attribute="tariff_name"]').html(tariff_name);
    $calcMsgContainer.find('span[data-attribute="period_min"]').html(period_min);
    $calcMsgContainer.find('span[data-attribute="period_max"]').html(period_max);
    this.message.show();
  }

  selectCity = function(_arg) {
    var $sel, details, id, opt, text;
    text = _arg.text, id = _arg.id, details = _arg.details;
    opt = new Option(text, id, true, true);
    $sel = this.cities.filter('[data-cdekcity="china"]');
    $sel.append(opt).trigger('change');
    this.params['senderCity'] = details['name_zho'];
    this.params['senderCityId'] = details['code'];
  }

  updateFromEvent = function(event) {
    var eventDetails;
    eventDetails = event.params.data.details;
    switch (event.target.getAttribute('data-cdekcity')) {
      case 'russia':
        $('#cdek_plugin_calculator_form_to_location_attributes_code').val(eventDetails['code'])
        this.params['receiverCity'] = eventDetails['name'];
        this.params['receiverCityId'] = eventDetails['code'];
        this.params['receiverRegion'] = eventDetails['regionName'];
        this.params['receiverRegionId'] = eventDetails['regionCode'];
        break;
      case 'china':
        this.params['senderCity'] = eventDetails['name_zho'];
        this.params['senderCityId'] = eventDetails['code'];
        break;
      default:
        false;
    }
  }

  updateFromResponse = function(_arg) {
    var delivery_sum, tariff_code;
    tariff_code = _arg.tariff_code, delivery_sum = _arg.delivery_sum;
    this.params['tariffId'] = tariff_code;
    this.params['deliverySum'] = delivery_sum;
    this.params['currency'] = 'RUB';
  }

  updateFromRequest = function() {
    this.params['weight'] = this.calcRequestForm.find("[name='" + this.inputPrefix + "[weight]']").val();
    this.params['length'] = this.calcRequestForm.find("[name='" + this.inputPrefix + "[length]']").val();
    this.params['width'] = this.calcRequestForm.find("[name='" + this.inputPrefix + "[width]']").val();
    this.params['height'] = this.calcRequestForm.find("[name='" + this.inputPrefix + "[height]']").val();
    this.params['deliveryMode'] = this.calcRequestForm.find("[name='" + this.inputPrefix + "[delivery_mode]'] option:selected").val();
  }
}
